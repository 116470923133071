<template>
  <Loader :show="loading"/>
  <HeaderHome/>
  <FiltroEvento/>
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-8">
          <h1>{{ contents.name }}</h1>
          <div v-html="contents.description"></div>
          <Atalhos/>
        </div>
        <div class="col-md-4">
          <FacebookSide/>
        </div>
      </div>
    </div>
  </div>
  <Newsletter/>
  <Footer/>
  <FooterEnd/>
</template>

<script>
import Contents from '@/api/Contents';
import {defineComponent} from 'vue';
import HeaderHome from '../components/HeaderHome.vue'
import FiltroEvento from '../components/FiltroEvento.vue'
import Footer from '../components/Footer.vue'
import FooterEnd from '../components/FooterEnd.vue'
import Newsletter from '../components/Newsletter.vue'
import FacebookSide from '../components/FacebookSide'
import Atalhos from "@/components/Atalhos";
import Loader from "../components/Loader";

export default defineComponent({
  name: 'Conteudo',
  components: {
    HeaderHome,
    FiltroEvento,
    Footer,
    FooterEnd,
    Atalhos,
    Newsletter,
    Loader,
    FacebookSide
  },
  data() {
    return {
      loading: true,
      contents: {
        name: '',
        description: ''
      },
      slug: ''
    }
  },
  methods: {
    fetch_content() {
      Contents.get_content_slug(this.slug).then((request) => {
        if (request.status == 200) {
          this.contents = request.data;
          this.loading = false;
        } else {
          this.$router.push("/conteudo/nao-encontrado/");
        }
      });
    }
  },
  mounted() {
    this.slug = this.$route.params.slug;
    this.fetch_content();
  },
  updated() {
    if (this.slug != this.$route.params.slug) {
      this.slug = this.$route.params.slug;
      this.loading = true;
      this.fetch_content();
    }
  }
});
</script>

<style>

</style>
